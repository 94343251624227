
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import CreateDiscountModal from "@/views/pages/Admin/discounts/components/CreateDiscountModal.vue";
import ShowDiscountsCodes from "@/views/pages/Admin/discounts/components/ShowDiscountsCodes.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "kt-discountTable-9",
  components: { CreateDiscountModal, ShowDiscountsCodes },
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n();
      const lists = ref();
      const add_discount = ref(false);
    const pagination = ref({
      total: 0,
      per_page: 0,
      current_page: 1,
    });
    const idHolder = ref();

    // get discounts
    function fetchDiscounts(page = 1) {
      ApiService.setHeader();
      ApiService.get(`admin/discount?page=${page}`)
        .then(({ data }) => {
          // success
          lists.value = data.data.discounts.data;
          pagination.value = data.data.discounts.pagination;
        })
        .catch((e) => {
          Swal.fire({
            text: "خطایی رخ داده است.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "دوباره تلاش کنید!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }
    fetchDiscounts();

    function setId(id) {
      idHolder.value = id;
    }

    // delete discounts
    function deleteItem(id) {
      ApiService.setHeader();
      ApiService.post("admin/discount/" + id + "/delete", [])
        .then(() => {
          // success
          fetchDiscounts();
        })
        .catch(() => {
          Swal.fire({
            text: "خطایی رخ داده است.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "دوباره تلاش کنید!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    const handleCurrentChange = (val) => {
      fetchDiscounts(val);
    };
    const discountValue = (discount) => {
      switch (discount.type) {
        case "percentage":
          return discount.discount_value + "%";
        default:
          return (
            Number(discount.discount_value).toLocaleString("fa") + " تومان"
          );
      }
    };

    const getStatus = (status) => {
      switch (status) {
        case "active":
          return '<span class="badge badge-light-success text-muted fw-bold text-muted d-block fs-7">فعال</span>';

        case "de_active":
          return '<span class="badge text-muted badge-light-danger d-block fs-7">غیر فعال</span>';
      }
    };

    const permission = ref();

    onMounted(() => {
      setCurrentPageBreadcrumbs(translate("تخفیف ها"), []);

      let user: any = localStorage.getItem("user_info");
      permission.value = JSON.parse(user).permissions;
    });

    return {
      lists,
      pagination,
      t,
      fetchDiscounts,
      idHolder,
      setId,
      deleteItem,
      handleCurrentChange,
      discountValue,
      getStatus,
      permission,
      add_discount,
    };
  },
});
