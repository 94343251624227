
import { defineComponent, ref, reactive, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";

interface NewAddressData {
  discount_id: number;
  count: number;
  code_length: number;
  prefix: string;
}

export default defineComponent({
  name: "show-codes-modal",
  components: {},
  props: {
    id: {
      type: Number,
      default: 0,
      require: true,
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);
    const pagination = ref({
      total: 0,
      per_page: 0,
      current_page: 1,
    });
    const state = reactive({
      tableData: [
        {
          date: "2016-05-02",
          name: "John Smith",
          address: "No.1518,  Jinshajiang Road, Putuo District",
        },
        {
          date: "2016-05-04",
          name: "John Smith",
          address: "No.1518,  Jinshajiang Road, Putuo District",
        },
        {
          date: "2016-05-01",
          name: "John Smith",
          address: "No.1518,  Jinshajiang Road, Putuo District",
        },
      ],
    });

    watch(props, function (newValue) {
      state.tableData = [];
      fetchDiscounts(newValue.id);
    });

    // get discounts
    function fetchDiscounts(id, page = 1) {
      ApiService.setHeader();
      ApiService.get(`admin/discount/${id}/codes?page=${page}`)
        .then(({ data }) => {
          // success
          state.tableData = data.data["discount-codes"].data;
          pagination.value = data.data["discount-codes"].pagination;
        })
        .catch((response) => {
          Swal.fire({
            text:
              response.data.data.validation_message ?? response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ادامه میدهم!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    }

    const formRef = ref<null | HTMLFormElement>(null);
    const targetData = ref<NewAddressData>({
      discount_id: props.id,
      count: 1,
      code_length: 8,
      prefix: "inboxino_",
    });

    const rules = ref({
      count: [
        {
          required: true,
          message: "لطفا تعداد تخفیف را وارد کنید",
          trigger: "blur",
        },
      ],
      code_length: [
        {
          required: true,
          message: "لطفا طول کاراکترهای کد تخفیف را مشخص کنید",
          trigger: "blue",
        },
      ],
      status: [
        {
          required: true,
          message: "لطفا پیشوند را مشخص کنید",
          trigger: "blur",
        },
      ],
    });

    const create_code = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          ApiService.setHeader();
          ApiService.post("admin/discount-code", {
            discount_id: props.id,
            count: targetData.value.count,
            code_length: targetData.value.code_length,
            prefix: targetData.value.prefix,
          })
            .then(() => {
              
              fetchDiscounts(props.id);
              loading.value = false;
              Swal.fire({
                text: "ذخیره سازی با موفقیت انجام شد.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "بسیار خب",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            })
            .catch((response) => {
              loading.value = false;
              Swal.fire({
                text:
                  response.data.data.validation_message ??
                  response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "ادامه میدهم!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              return false;
            });
        }
      });
    };

    function deleteItem(id) {
      ApiService.setHeader();
      ApiService.post("admin/discount-code/" + id + "/delete", [])
        .then(() => {
          // success
          fetchDiscounts(props.id);
        })
        .catch(() => {
          Swal.fire({
            text: "خطایی رخ داده است.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "دوباره تلاش کنید!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    const handleCurrentChange = (val) => {
      fetchDiscounts(props.id, val);
    };

    return {
      state,
      targetData,
      create_code,
      loading,
      formRef,
      rules,
      deleteItem,
      pagination,
      handleCurrentChange,
    };
  },
});
