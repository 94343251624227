
import {
  defineComponent,
  ref,
  reactive,
  watch,
  onMounted,
  onUpdated,
} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import DatePicker from "vue3-persian-datetime-picker";
import ApiService from "@/core/services/ApiService";
import { storeToRefs } from "pinia";
import { platforms } from "@/store/pinia/Platforms";
import { getLocale } from "@/core/services/HelpersFun";
import { allPackages } from "@/store/pinia/AllPackages";
interface NewAddressData {
  name: string;
  type: string;
  status: string;
  discount_value: number;
  order_types: Array<string>;
  start_datetime: string;
  finish_datetime: string;
  code_use_count: number;
  code_use_count_per_user: number;
  first_order: string;
  special_users: any;
  users: Array<any>;
  platforms: Array<string>;
  packages: Array<string>;
  only_for_boundless: string;
}

export default defineComponent({
  name: "create-discount-modal",
  components: { DatePicker },
  emits: ["refreshlist", "closeModal"],
  props: {
    id: {
      type: Number,
      default: 0,
      require: true,
    },
  },
  setup(props, context) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    const packageListHolder = allPackages();
    const { packageList } = storeToRefs(packageListHolder);

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const users = ref([]);
    const show_users = ref([]);
    const list = ref();

    const info = reactive({
      title: "ایجاد تخفیف جدید",
      url: "admin/discount",
    });

    const targetData = ref<NewAddressData>({
      name: "",
      type: "percentage",
      status: "active",
      discount_value: 0,
      order_types: [],
      start_datetime: "",
      finish_datetime: "",
      code_use_count: 0,
      code_use_count_per_user: 0,
      first_order: "true",
      special_users: 0,
      users: [],
      platforms: [],
      packages: [],
      only_for_boundless: "no",
    });

    const range = ref([]);
    const startdate = ref();
    const enddate = ref();

    // search user

    const filter = ref({
      user_id: [],
    });

    const searchTerm = ref("");
    const searchResults = ref();
    let timer = Number as any;
    watch(searchTerm, async (newSearchTerm) => {
      // Clear the previous timer
      clearTimeout(timer);

      // Set a new timer to delay the search request by 2 seconds
      timer = setTimeout(async () => {
        // Perform a search with the new search term
        const results = await performSearch(newSearchTerm);
        searchResults.value = results;
      }, 2000);
    });

    const userslist = ref();
    const loadingSearchUser = ref(false);
    async function performSearch(searchTerm) {
      loadingSearchUser.value = true;
      const response = await ApiService.query("admin/user", {
        params: { q: searchTerm },
      });

      const results = await response;
      if (results && results.data) {
        loadingSearchUser.value = false;
        userslist.value = results.data.data.users.data;
      }
    }

    const getItems = () => {
      loading.value = true;
      ApiService.query("admin/bot", { params: filter.value })
        .then(({ data }) => {
          loading.value = false;
          list.value = data.data.bots.data;
          // list.value.map((item, index) => {
          //   changeServerValue.value[index] = item.server;
          // });
          // pagination.value = data.data.bots.pagination;
        })
        .catch(() => {
          loading.value = false;
        });

      targetData.value.users = filter.value.user_id;
      // console.log(filter.value);
    };

    // end search user

    const rules = ref({
      name: [
        {
          required: true,
          message: "لطفا عنوان تخفیف را وارد کنید",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "لطفا نوع تخفیف را مشخص کنید",
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: "لطفا وضعیت را مشخص کنید",
          trigger: "change",
        },
      ],
      discount_value: [
        {
          required: true,
          message: "لطفا نوع مقدار را مشخص کنید",
          trigger: "blur",
        },
      ],
      order_types: [
        {
          required: true,
          message: "لطفا مقدار را وارد کنید",
          trigger: "change",
        },
      ],
      start_datetime: [
        {
          required: true,
          message: "لطفا تاریخ شروع را انتخاب کنید",
          trigger: "change",
        },
      ],
      finish_datetime: [
        {
          required: true,
          message: "لطفا تاریخ اتمام را انتخاب کنید",
          trigger: "change",
        },
      ],
      code_use_count: [
        {
          required: false,
          message: "لطفا تعداد کدهای تخفیف را مشخص کنید",
          trigger: "blur",
        },
      ],
      code_use_count_per_user: [
        {
          required: false,
          message: "لطفا تعداد استفاده برای هر کاربر را مشخص کنید",
          trigger: "blur",
        },
      ],
      first_order: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "blur",
        },
      ],
      users: [
        {
          required: false,
          message: "تخفیف برای اولین سفارش فعال باشد یا خیر",
          trigger: "blur",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      if (startdate.value) targetData.value.start_datetime = startdate.value;
      if (enddate.value) targetData.value.finish_datetime = enddate.value;
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          ApiService.setHeader();
          let data = { ...targetData.value };
          if (!data.special_users) data.users = [];

          ApiService.post(info.url, data)
            .then(() => {
              targetData.value = {
                name: "",
                type: "percentage",
                status: "active",
                discount_value: 0,
                order_types: [],
                start_datetime: "",
                finish_datetime: "",
                code_use_count: 0,
                code_use_count_per_user: 0,
                first_order: "true",
                special_users: 0,
                users: [],
                platforms: [],
                packages: [],
                only_for_boundless: "no",
              };
              loading.value = false;
              context.emit("refreshlist");
              context.emit("closeModal");
              Swal.fire({
                text: "ذخیره سازی با موفقیت انجام شد.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "بسیار خب",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            })
            .catch(({ response }) => {
              loading.value = false;
              Swal.fire({
                text:
                  response.data.data.validation_message ??
                  "خطایی رخ داده است. ورودی هارا چک کرده و دوباره امتحان کنید.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "ادامه میدهم!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              return false;
            });
        }
      });
    };

    function fetchUsers() {
      ApiService.setHeader();
      ApiService.get("admin/user/all")
        .then(({ data }) => {
          users.value = data.data.users;
        })
        .catch((e) => {
          return;
        });
    }

    watch(props, function (newValue) {
      targetData.value = {
        name: "",
        type: "percentage",
        status: "active",
        discount_value: 0,
        order_types: [],
        start_datetime: "",
        finish_datetime: "",
        code_use_count: 0,
        code_use_count_per_user: 0,
        first_order: "true",
        special_users: 0,
        users: [],
        platforms: [],
        packages: [],
        only_for_boundless: "no",
      };
      if (newValue.id > 0) {
        info.title = "ویرایش تخفیف";
        info.url = "admin/discount/" + newValue.id;
        fetchDiscountInfo(newValue.id);
      } else {
        info.title = "ایجاد تخفیف جدید";
        info.url = "admin/discount";
      }
    });

    onMounted(() => {
      if (props.id > 0) {
        info.title = "ویرایش تخفیف";
        info.url = "admin/discount/" + props.id;
        fetchDiscountInfo(props.id);
      } else {
        info.title = "ایجاد تخفیف جدید";
        info.url = "admin/discount";
      }

      fetchUsers();
    });

    const loadingDiscount = ref(false);

    function fetchDiscountInfo(id) {
      loadingDiscount.value = true;
      ApiService.setHeader();
      ApiService.get("admin/discount/" + id)
        .then(({ data }) => {
          targetData.value.name = data.data.discount.name;
          targetData.value.type = data.data.discount.type;
          targetData.value.status = data.data.discount.status;
          targetData.value.only_for_boundless =
            data.data.discount.only_for_boundless;
          if (data.data.discount && data.data.discount.platforms) {
            targetData.value.platforms = data.data.discount.platforms;
          } else {
            targetData.value.platforms = [];
          }

          if (data.data.discount && data.data.discount.packages) {
            targetData.value.packages = data.data.discount.packages.map(
              (item) => item.id
            );
          } else {
            targetData.value.packages = [];
          }

          targetData.value.discount_value = data.data.discount.discount_value;
          targetData.value.special_users = data.data.discount.users.length;
          targetData.value.first_order = data.data.discount.first_order;
          targetData.value.code_use_count = data.data.discount.code_use_count;
          targetData.value.code_use_count_per_user =
            data.data.discount.code_use_count_per_user;
          targetData.value.users = data.data.discount.users.map((user) => user);
          show_users.value = data.data.discount.users.map((user) => user);
          filter.value.user_id = data.data.discount.users.map(
            (user) => user.id
          );

          if (data.data.discount.start_datetime)
            setStartDate(data.data.discount.start_datetime.gregorian);
          if (data.data.discount.finish_datetime)
            setEndDate(data.data.discount.finish_datetime.gregorian);
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          loadingDiscount.value = false;
        });
    }

    function setStartDate(str) {
      startdate.value = str.substring(0, str.length - 3);
    }

    function setEndDate(str) {
      enddate.value = str.substring(0, str.length - 3) ?? "";
    }
    loading.value = false;
    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      translate,
      users,
      range,
      startdate,
      enddate,
      info,
      platformsList,
      getLocale,
      loadingDiscount,
      packageList,
      getItems,
      userslist,
      searchTerm,
      searchResults,
      performSearch,
      filter,
      show_users,
    };
  },
});
